import * as _ from "lodash";

let _wavetablePlayerNode = null;
if ("AudioWorkletNode" in window) {
  class _WavetablePlayerNode extends AudioWorkletNode {
    constructor(context) {
      super(context, "wavetable-player-processor");
      this.id = _.uniqueId();
      this.position = 0;
      this.wtPosition = null;
      this.playing = false;
      this.port.onmessage = event => {
        this.position = event.data.position;
        this.wtPosition = event.data.wtPosition;
      };
    }

    get wavetablePos() {
      return this.parameters.get("wtPos");
    }

    set note(note) {
      this.port.postMessage({ playbackRate: 2 ** ((note - 60) / 12) });
    }

    set wavetable(data) {
      this.port.postMessage(data);
    }

    start() {
      this.port.postMessage({ command: "start" });
      this.position = 0;
      this.wtPosition = null;
      this.playing = true;
    }

    stop() {
      this.port.postMessage({ command: "stop" });
      this.playing = false;
    }
  }
  _wavetablePlayerNode = _WavetablePlayerNode;
}

export const WavetablePlayerNode = _wavetablePlayerNode;
