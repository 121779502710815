import Tone from "tone";
import { PLAYBACK_SR } from "./constants";

const audioctx = new AudioContext({ sampleRate: PLAYBACK_SR });
Tone.setContext(audioctx);

let ctxPromise = new Promise((res, rej) => {
  if (!audioctx.audioWorklet) {
    rej("Worklets not supported");
  } else {
    return audioctx.audioWorklet
      .addModule("wavetablePlayerProcessor.min.js")
      .then(() => res(audioctx))
      .catch(rej);
  }
});

export function getAudioCtx() {
  return ctxPromise;
}
