import * as _ from "lodash";
import chroma from "chroma-js";

import { N_LATENTS } from "./constants";

let mm = window.mm;
let tf = mm.tf;

export const PRESET_ZS = [
  {
    name: "Brassy",
    color: "red",
    z: tf.tensor1d([
      2.1388697624206543,
      0.5200455188751221,
      -0.1433996707201004,
      1.0027639865875244,
      1.1129631996154785,
      -0.2148512452840805,
      -1.2952396869659424,
      -0.6157272458076477,
      2.8747053146362305,
      -0.13538263738155365,
      1.2554906606674194,
      0.010508579201996326,
      0.3481362760066986,
      0.13752466440200806,
      0.1252899169921875,
      -0.17756995558738708,
      -1.1241337060928345,
      0.8301228880882263,
      -0.8758623003959656,
      -1.5744304656982422,
      -0.1439511477947235,
      0.8424021601676941,
      1.0936678647994995,
      1.0383769273757935,
      0.04725770279765129,
      -0.5077395439147949,
      0.7571263313293457,
      0.5218470096588135,
      0.11250028014183044,
      -1.5615190267562866,
      1.2370537519454956,
      -0.018964329734444618,
      -0.9719473123550415,
      0.09674417227506638,
      1.1397613286972046,
      0.21711839735507965,
      -0.6956132054328918,
      0.49386751651763916,
      -0.7322367429733276,
      -1.0866849422454834,
      0.49708276987075806,
      0.33266928791999817,
      0.23448042571544647,
      -0.3736508786678314,
      1.6126575469970703,
      -0.8127854466438293,
      -1.6379384994506836,
      1.672790288925171,
      -1.106543779373169,
      0.9265162348747253,
      -0.770876944065094,
      -1.0413357019424438,
      0.1283821314573288,
      0.7207096219062805,
      -0.7219877243041992,
      -0.2980724573135376,
      -0.48438531160354614,
      0.06812086701393127,
      0.10138924419879913,
      -0.8001379370689392,
      -0.725879967212677,
      0.2878996431827545,
      -0.3435937762260437,
      -2.7734992504119873,
      2.510910987854004,
      -0.48274654150009155,
      0.5739699602127075,
      -0.9583241939544678,
      0.29659560322761536,
      -1.4457837343215942,
      -0.1666870415210724,
      -0.360319048166275,
      1.9676899909973145,
      0.5245113968849182,
      0.3925594091415405,
      0.06566910445690155,
      -3.490541696548462,
      0.9734471440315247,
      -0.04580869898200035,
      -0.47725212574005127,
      -0.27241095900535583,
      0.5428203344345093,
      -0.6592797636985779,
      0.4912142753601074,
      1.0761553049087524,
      1.9994429349899292,
      -0.5838301777839661,
      0.12056014686822891,
      0.020293088629841805,
      1.4297970533370972,
      0.47074541449546814,
      -0.09889493137598038,
      1.0721323490142822,
      2.2686166763305664,
      -0.0426500029861927,
      0.2127903550863266,
      -0.24633999168872833,
      0.6825271248817444,
      0.11656013131141663,
      1.5053553581237793,
      -1.1833553314208984,
      0.807157039642334,
      -0.4465925395488739,
      0.8805246353149414,
      -1.142377495765686,
      0.8908900022506714,
      1.1591424942016602,
      -0.9295024871826172,
      -0.7793886661529541,
      0.06792338937520981,
      -1.3927348852157593,
      0.7735150456428528,
      0.6582921147346497,
      -0.1906382441520691,
      0.6381374597549438,
      -0.1957603394985199,
      0.4023919105529785,
      1.1868692636489868,
      2.006160259246826,
      -0.9024627804756165,
      -0.09716498106718063,
      0.2912905514240265,
      -0.21599820256233215,
      -0.27934715151786804,
      0.29138612747192383,
      -0.6241276264190674,
      0.4475051760673523,
      -0.11488835513591766,
      0.5964405536651611,
      0.574881911277771,
      -1.9180508852005005,
      -1.372435450553894,
      0.2859630584716797,
      0.010807045735418797,
      -0.6520188450813293,
      0.86606764793396,
      -0.658545970916748,
      -0.9662272334098816,
      1.2323452234268188,
      0.9878060221672058,
      -0.5223047733306885,
      0.40820595622062683,
      0.6279792785644531,
      0.37932825088500977,
      -0.02945065125823021,
      -0.9574284553527832,
      -0.7012268900871277,
      -0.535073459148407,
      -0.25860893726348877,
      -0.994516134262085,
      -0.8985235095024109,
      -0.19799689948558807,
      0.0956166535615921,
      0.2622891366481781,
      -0.5385410189628601,
      -2.019763708114624,
      0.5538696646690369,
      1.1724570989608765,
      0.4532172977924347,
      1.1073611974716187,
      -0.3472839295864105,
      -0.17975379526615143,
      -0.6898941397666931,
      -0.42411211133003235,
      -0.2216266542673111,
      1.0708070993423462,
      -0.797822892665863,
      1.8496814966201782,
      -2.106914520263672,
      0.6357648968696594,
      0.3534316420555115,
      1.3758572340011597,
      0.5261971950531006,
      -1.0518327951431274,
      0.03985726460814476,
      0.4566360414028168,
      0.3654261827468872,
      -0.21482223272323608,
      -1.3692295551300049,
      -0.804283618927002,
      1.0954684019088745,
      -0.006083144806325436,
      1.2960468530654907,
      1.6178128719329834,
      -2.143150806427002,
      -1.1698232889175415,
      0.49143677949905396,
      0.7820851802825928,
      -0.7206317782402039,
      -0.7173563838005066,
      -0.43624675273895264,
      -1.1265177726745605,
      0.5163990259170532,
      -0.9199394583702087,
      0.0015791981713846326,
      -0.27151060104370117,
      0.28672799468040466,
      -1.1621308326721191,
      -0.03251102566719055,
      0.19936102628707886,
      0.59433513879776,
      0.03119896538555622,
      1.242486834526062,
      2.5859992504119873,
      0.13784699141979218,
      -0.24446886777877808,
      -0.514305055141449,
      -0.28804880380630493,
      -1.4029560089111328,
      -0.1778661608695984,
      -0.6177629232406616,
      0.32526177167892456,
      0.06114377826452255,
      1.1743139028549194,
      0.020963292568922043,
      1.2832103967666626,
      1.7686914205551147,
      2.856600046157837,
      -0.52597576379776,
      0.6826586723327637,
      0.642370343208313,
      -1.1619360446929932,
      -0.1668260097503662,
      1.2715873718261719,
      -1.5697466135025024,
      1.1617709398269653,
      -0.3788565397262573,
      -1.5147608518600464,
      -0.5577782392501831,
      0.10664112120866776,
      -0.5036091804504395,
      -0.20771948993206024,
      0.0036629021633416414,
      -0.9498938918113708,
      0.21539326012134552,
      0.25928252935409546,
      -1.8171863555908203,
      -1.3215689659118652,
      0.6366847157478333,
      0.4626094400882721,
      -0.994525134563446,
      0.6042976975440979,
      -0.34644654393196106,
      -1.2551761865615845,
      0.576833188533783,
      0.7003967761993408,
      -0.03927072137594223,
      0.3405286967754364,
      0.4800434112548828,
      0.12949810922145844,
      1.200720191001892,
      0.17275361716747284,
      1.074712872505188,
      -1.821281909942627,
      -0.661372721195221,
      0.23910003900527954
    ])
  },
  {
    name: "Mallet",
    color: "#7615ff",
    z: tf.tensor1d([
      -0.8125194311141968,
      1.462227702140808,
      -1.1068425178527832,
      0.0090788584202528,
      1.0753507614135742,
      0.2615174353122711,
      -0.09123287349939346,
      0.3521548807621002,
      0.05955943092703819,
      -0.43256208300590515,
      1.6190792322158813,
      -0.3982532322406769,
      0.46851807832717896,
      0.5754522681236267,
      1.5638924837112427,
      -1.6911182403564453,
      -1.84473717212677,
      -0.47486060857772827,
      1.062609314918518,
      -0.4496577978134155,
      -0.8672254681587219,
      0.1324021965265274,
      -0.9419723153114319,
      0.9944620132446289,
      -0.8529456853866577,
      -1.1571751832962036,
      1.299051284790039,
      -1.2596389055252075,
      -0.4315319359302521,
      -0.6218771934509277,
      -0.6829990148544312,
      -0.4833935499191284,
      -0.9028856158256531,
      -2.0787675380706787,
      1.1661698818206787,
      -0.5393858551979065,
      1.7145452499389648,
      -0.37248483300209045,
      -0.7135971784591675,
      0.42143455147743225,
      -0.7749553322792053,
      -0.3288149833679199,
      0.3756011128425598,
      -0.016506824642419815,
      1.898092269897461,
      0.7991120219230652,
      0.5302783846855164,
      1.0787303447723389,
      1.7489075660705566,
      0.03594770282506943,
      1.1602777242660522,
      0.8950818181037903,
      -0.5540480017662048,
      -1.1661081314086914,
      -0.38315337896347046,
      -1.0476434230804443,
      0.016956979408860207,
      1.6243895292282104,
      1.1687601804733276,
      -0.9306980967521667,
      0.33052778244018555,
      2.6979377269744873,
      -2.1169729232788086,
      -0.9205216765403748,
      -0.3835383653640747,
      -1.9257606267929077,
      1.207210898399353,
      -0.5263962745666504,
      1.1455625295639038,
      -0.6145236492156982,
      0.5213829874992371,
      -0.9649280309677124,
      -0.6545835137367249,
      0.0827116072177887,
      1.7565687894821167,
      1.0516209602355957,
      -0.7156487107276917,
      0.09661582857370377,
      0.9868029952049255,
      -0.507648766040802,
      -1.4690643548965454,
      -0.39296573400497437,
      1.48268723487854,
      -0.9272215366363525,
      -2.4756064414978027,
      -0.6519635915756226,
      1.5166778564453125,
      -0.7348121404647827,
      -0.26907896995544434,
      -0.17436011135578156,
      -1.0325746536254883,
      0.3417138159275055,
      -0.7430722713470459,
      -1.0360511541366577,
      1.058206558227539,
      0.6563745141029358,
      1.6865448951721191,
      -0.5964406728744507,
      0.39368242025375366,
      -0.8436993956565857,
      0.635858416557312,
      -0.9403310418128967,
      0.7158493399620056,
      -0.6388912796974182,
      -1.4789836406707764,
      -1.0416500568389893,
      0.7559953331947327,
      -2.3028929233551025,
      -0.5717154145240784,
      0.7152073979377747,
      -0.1569637954235077,
      -0.0054833428002893925,
      0.2822090685367584,
      -1.8068819046020508,
      1.3763659000396729,
      -0.335917592048645,
      -1.9773024320602417,
      -1.0879154205322266,
      0.6979227066040039,
      -0.2021278291940689,
      0.2350209802389145,
      -0.8758161067962646,
      -0.7365216016769409,
      -0.5576580762863159,
      -0.9658675789833069,
      -0.05200713127851486,
      1.2803407907485962,
      -0.429546982049942,
      -1.2025279998779297,
      -0.9633161425590515,
      -1.2657151222229004,
      0.051097627729177475,
      1.36652410030365,
      -1.3656442165374756,
      -0.903096616268158,
      1.6951533555984497,
      -1.083724856376648,
      -0.4739249348640442,
      0.26125675439834595,
      -0.15911328792572021,
      -0.15807609260082245,
      0.536679208278656,
      2.1976006031036377,
      -1.2368698120117188,
      -0.7467168569564819,
      2.2091493606567383,
      0.14830045402050018,
      -0.5998777151107788,
      -0.5106450319290161,
      0.27901336550712585,
      0.5460926294326782,
      -0.7300494909286499,
      0.08781959116458893,
      -0.7086796760559082,
      2.1835269927978516,
      -0.46970051527023315,
      1.532728672027588,
      1.7612318992614746,
      1.0843671560287476,
      0.42439618706703186,
      -0.519091010093689,
      0.3216469883918762,
      0.8795676231384277,
      -0.9233251810073853,
      -0.3845314383506775,
      2.0045619010925293,
      1.4335031509399414,
      -0.30548548698425293,
      -0.5801258683204651,
      0.28768613934516907,
      0.19266432523727417,
      -0.6844891905784607,
      0.4247191250324249,
      -1.493546724319458,
      0.796245813369751,
      0.09468646347522736,
      -0.46497100591659546,
      -0.01207751501351595,
      0.27373361587524414,
      1.5316475629806519,
      -0.11922431737184525,
      1.3316816091537476,
      -1.4453516006469727,
      0.5576928853988647,
      0.32393747568130493,
      -0.7092953324317932,
      -1.4826356172561646,
      -0.3761860132217407,
      0.20826895534992218,
      -1.2355955839157104,
      1.7097690105438232,
      -0.6819741129875183,
      -0.3328692317008972,
      -0.3874352276325226,
      -0.7972626686096191,
      -0.3573739528656006,
      -0.8392273783683777,
      1.0926072597503662,
      0.48573940992355347,
      -1.9837307929992676,
      -0.3944585919380188,
      0.8950678706169128,
      -1.09706711769104,
      1.4609912633895874,
      0.5110174417495728,
      0.43349096179008484,
      -1.2399803400039673,
      -0.04437282308936119,
      -0.42076927423477173,
      1.0877602100372314,
      -0.22740258276462555,
      0.07856691628694534,
      -1.0656845569610596,
      0.5077011585235596,
      1.8921226263046265,
      -0.670195460319519,
      1.3736213445663452,
      1.4370518922805786,
      -0.4448997378349304,
      0.3421725928783417,
      0.8244592547416687,
      0.5263515710830688,
      -0.03264152258634567,
      -0.30666384100914,
      -0.06536209583282471,
      0.9998751282691956,
      0.6017478108406067,
      -1.0490607023239136,
      0.6744703054428101,
      0.14561617374420166,
      0.9773744940757751,
      -2.526458740234375,
      0.49776825308799744,
      0.3119511008262634,
      -0.7952487468719482,
      -0.6724359393119812,
      0.7861140370368958,
      -2.4538767337799072,
      1.5907551050186157,
      -0.5847100019454956,
      0.13544702529907227,
      0.002888424787670374,
      -1.7561544179916382,
      0.4801751673221588,
      0.2919315695762634,
      -1.1656527519226074,
      -0.49175432324409485,
      -0.09754794090986252,
      2.2055883407592773,
      0.26991981267929077,
      -0.19207799434661865,
      -0.08371660858392715,
      -0.6378251314163208,
      -1.142878532409668,
      -0.6638874411582947,
      0.14028474688529968
    ])
  },
  {
    name: "Stringy",
    color: "magenta",
    z: tf.tensor1d([
      -1.956885814666748,
      -0.07708833366632462,
      0.18979263305664062,
      -0.22900259494781494,
      -0.8060659170150757,
      -1.535766839981079,
      0.3649227023124695,
      -0.2319767326116562,
      -0.3400684595108032,
      0.48575359582901,
      0.11156219244003296,
      0.7595533728599548,
      0.7045559287071228,
      -1.999233365058899,
      1.459901213645935,
      -0.21778592467308044,
      -0.742706298828125,
      1.6642374992370605,
      0.16068002581596375,
      0.7923585176467896,
      -2.1672794818878174,
      1.056556224822998,
      -0.5305308103561401,
      -0.8115828037261963,
      0.6189526915550232,
      0.6580053567886353,
      -0.9991560578346252,
      -2.4754798412323,
      0.029031578451395035,
      -0.5155187249183655,
      2.7183997631073,
      -0.6486976742744446,
      -1.612796425819397,
      -0.01391210313886404,
      -1.1450239419937134,
      -1.7390575408935547,
      1.2978469133377075,
      1.1773332357406616,
      -0.9087948203086853,
      1.5356101989746094,
      -1.2525591850280762,
      1.671897053718567,
      -1.115639090538025,
      0.7949866652488708,
      1.4402110576629639,
      1.3524978160858154,
      -0.905035138130188,
      0.6377173066139221,
      1.2066413164138794,
      -0.04929748550057411,
      -2.306650161743164,
      0.5258749127388,
      -0.04510868340730667,
      0.5556665062904358,
      0.17753300070762634,
      0.3805953860282898,
      1.2704062461853027,
      0.2167276293039322,
      0.9651128649711609,
      -0.16728806495666504,
      -0.7501024007797241,
      -0.8078693151473999,
      1.9990687370300293,
      0.3710225224494934,
      0.39129871129989624,
      1.4136600494384766,
      1.6697676181793213,
      0.6316800713539124,
      -0.36351925134658813,
      -0.671602725982666,
      -0.5888482332229614,
      0.45010876655578613,
      0.2479686141014099,
      2.0184221267700195,
      0.2584742605686188,
      0.25098875164985657,
      0.06751898676156998,
      -1.2237082719802856,
      -1.9556200504302979,
      0.025848601013422012,
      0.3407057523727417,
      -1.3199727535247803,
      0.231733039021492,
      -1.5512735843658447,
      -0.16372135281562805,
      -1.3914661407470703,
      0.7630908489227295,
      1.2975237369537354,
      1.7830997705459595,
      1.4259464740753174,
      -0.15069988369941711,
      -0.3496207594871521,
      0.8538064360618591,
      1.82649564743042,
      1.0178309679031372,
      -0.22720888257026672,
      0.883053183555603,
      -0.9645559191703796,
      0.3097127676010132,
      -0.4880109429359436,
      -0.2755598723888397,
      -1.4928635358810425,
      0.3275963068008423,
      -1.6788747310638428,
      -0.31612348556518555,
      2.098278284072876,
      0.5378836989402771,
      0.40755558013916016,
      -0.38825613260269165,
      -1.557803750038147,
      2.281564950942993,
      -0.5102199912071228,
      -0.15768630802631378,
      -0.5576779246330261,
      0.9790685176849365,
      1.0030852556228638,
      -1.1545149087905884,
      1.8636027574539185,
      -0.05523025244474411,
      0.12125474959611893,
      0.28413131833076477,
      2.0894856452941895,
      1.588243842124939,
      0.49328142404556274,
      0.6699612140655518,
      1.1107362508773804,
      0.9690529704093933,
      -1.1406872272491455,
      0.9128240942955017,
      -0.5771709680557251,
      1.4571212530136108,
      1.0858840942382812,
      0.0011804853565990925,
      2.2474539279937744,
      -0.2833223044872284,
      0.41371503472328186,
      0.48547911643981934,
      0.1925118863582611,
      1.1150007247924805,
      -0.5934332609176636,
      -0.5471971035003662,
      -0.02137562818825245,
      -0.035696033388376236,
      -0.5982862710952759,
      -1.8852057456970215,
      0.5774107575416565,
      0.931564450263977,
      -3.198824882507324,
      -0.5858423709869385,
      -0.5774834752082825,
      0.36421042680740356,
      1.834291696548462,
      0.1460411250591278,
      1.8568847179412842,
      1.0611262321472168,
      1.6748470067977905,
      -0.42700859904289246,
      -0.07432474941015244,
      0.07283241301774979,
      -0.2492644488811493,
      -0.3113820254802704,
      0.8792715668678284,
      -1.4178447723388672,
      -0.5097606778144836,
      -0.059166450053453445,
      1.5329053401947021,
      1.2030268907546997,
      0.49512115120887756,
      1.3752977848052979,
      1.1531399488449097,
      -0.052692219614982605,
      0.10148221999406815,
      0.6216568946838379,
      -0.24914512038230896,
      0.5132712721824646,
      -1.4394057989120483,
      -0.07111650705337524,
      0.8096884489059448,
      -1.8594321012496948,
      -0.8067402243614197,
      -0.037326518446207047,
      0.14686918258666992,
      -1.672640085220337,
      -0.2581809461116791,
      -0.7288416028022766,
      -0.2815595269203186,
      -0.024351919069886208,
      -1.5338869094848633,
      0.8018113374710083,
      0.8870499134063721,
      -0.11827632784843445,
      -0.33981114625930786,
      1.03886878490448,
      -0.4874989092350006,
      0.6812824606895447,
      0.3191830813884735,
      -1.4585347175598145,
      -0.1353318840265274,
      -0.20234857499599457,
      0.6211320161819458,
      -1.4589922428131104,
      -1.1821198463439941,
      1.2643194198608398,
      0.6358118057250977,
      0.8792501091957092,
      0.25705745816230774,
      -0.915023922920227,
      0.17888551950454712,
      -1.5689451694488525,
      0.9553632140159607,
      -0.569540798664093,
      0.3947840929031372,
      0.6131327748298645,
      -0.4017060399055481,
      -0.8234356641769409,
      -0.9852182269096375,
      -1.2120037078857422,
      0.7235366106033325,
      -0.16658514738082886,
      0.3706165552139282,
      0.5115005970001221,
      -0.2512085735797882,
      -0.18579144775867462,
      -0.44035521149635315,
      -0.05171322822570801,
      -1.925271987915039,
      -1.9917141199111938,
      0.9576733708381653,
      -0.1581335961818695,
      -0.28429752588272095,
      0.16086016595363617,
      -0.01685076393187046,
      0.13740214705467224,
      -0.011201360262930393,
      -0.26750412583351135,
      -0.12803895771503448,
      -0.17643937468528748,
      0.8250631093978882,
      -0.5255414843559265,
      -0.9413190484046936,
      -1.5466423034667969,
      -0.38393306732177734,
      -0.2166355848312378,
      -0.04350275918841362,
      2.1122186183929443,
      0.12009013444185257,
      -0.5573631525039673,
      -0.14011472463607788,
      1.6592541933059692,
      -0.23998847603797913,
      -0.06424801051616669,
      -1.2437487840652466,
      0.637478232383728,
      -0.6487982273101807,
      -0.5108371376991272,
      -1.1531763076782227
    ])
  },
  {
    name: "Plucky",
    color: "#03CEFF",
    z: tf.tensor1d([
      0.3663838803768158,
      -0.14301763474941254,
      0.047573428601026535,
      0.6998927593231201,
      1.2862876653671265,
      2.0980238914489746,
      0.442200630903244,
      -1.2525278329849243,
      -0.3969220519065857,
      0.9632529616355896,
      -0.696900486946106,
      0.614886999130249,
      0.04647943004965782,
      -0.30842193961143494,
      0.03374265879392624,
      0.7198019027709961,
      -1.5707383155822754,
      -0.1520313173532486,
      0.7107512950897217,
      -0.9266787171363831,
      -0.8616651296615601,
      1.755035400390625,
      0.21943649649620056,
      -1.406708002090454,
      0.48966413736343384,
      0.09886449575424194,
      0.3122205436229706,
      0.692689061164856,
      0.7890725135803223,
      -1.0290873050689697,
      -0.27883264422416687,
      0.29559051990509033,
      1.6150165796279907,
      -0.4967271089553833,
      -0.44674918055534363,
      -0.9133809804916382,
      1.6414719820022583,
      -0.2567148804664612,
      -0.8563734889030457,
      0.6710406541824341,
      -1.1629307270050049,
      0.6525720357894897,
      -2.1746110916137695,
      -0.03734537214040756,
      1.2258210182189941,
      -0.4998660683631897,
      0.6840382814407349,
      0.7999628782272339,
      -0.3471927046775818,
      -0.07253409922122955,
      -0.38033920526504517,
      1.1604645252227783,
      -1.9162968397140503,
      -0.6800695657730103,
      0.5353118181228638,
      -1.185488224029541,
      -0.6992824077606201,
      0.2887161374092102,
      0.5847269296646118,
      -0.563011109828949,
      0.39539918303489685,
      -2.8246560096740723,
      1.4116665124893188,
      -1.4563086032867432,
      -0.09710817039012909,
      -0.3681608736515045,
      0.25626304745674133,
      0.17148248851299286,
      0.12563219666481018,
      -0.9904530048370361,
      -0.7979929447174072,
      0.4027542769908905,
      0.7322812676429749,
      0.5912790298461914,
      -0.6194436550140381,
      0.8052921891212463,
      1.6232959032058716,
      -0.2776884436607361,
      -1.3370599746704102,
      1.8870896100997925,
      1.713779330253601,
      0.546198308467865,
      -0.4711127281188965,
      -0.16332295536994934,
      -2.377103567123413,
      -0.9688752293586731,
      1.246026873588562,
      0.3835888206958771,
      -1.0137202739715576,
      0.531215250492096,
      -1.0761021375656128,
      -0.32366472482681274,
      -0.5831420421600342,
      -3.2167491912841797,
      -0.2766195833683014,
      0.9140509963035583,
      0.10995107144117355,
      -0.20392589271068573,
      -0.8754919767379761,
      -0.14212843775749207,
      2.8447585105895996,
      1.0945972204208374,
      -0.9001445770263672,
      0.8881785869598389,
      -0.9990237355232239,
      -0.11819355934858322,
      0.002210943028330803,
      -0.669025719165802,
      -0.25703558325767517,
      0.4294579029083252,
      -0.3377595543861389,
      0.7157863974571228,
      -0.227518692612648,
      0.6824937462806702,
      -0.6258102059364319,
      -0.20562005043029785,
      -0.7443199157714844,
      -0.9848307967185974,
      -1.7710349559783936,
      -0.7756597399711609,
      0.32332250475883484,
      -0.3119274079799652,
      0.19005639851093292,
      0.019792892038822174,
      -1.7172560691833496,
      -0.3375636041164398,
      -1.2685916423797607,
      0.6755476593971252,
      0.16766007244586945,
      -0.3627074658870697,
      0.8384621143341064,
      -0.16182751953601837,
      -0.39253440499305725,
      -1.190999984741211,
      0.2341107726097107,
      -1.9758472442626953,
      -1.10880446434021,
      1.91482675075531,
      0.668653130531311,
      -0.7636182904243469,
      0.30966922640800476,
      0.6116503477096558,
      -1.5764509439468384,
      -0.5797991752624512,
      0.05899368226528168,
      -0.5754252672195435,
      -0.5202285051345825,
      0.44290369749069214,
      -0.26603057980537415,
      -0.11816522479057312,
      1.7438369989395142,
      0.0718025490641594,
      -1.0994070768356323,
      2.2714388370513916,
      -0.38772112131118774,
      -0.36532482504844666,
      -0.40735697746276855,
      1.200245976448059,
      -1.542319655418396,
      0.8613904714584351,
      1.62774658203125,
      -0.5636923313140869,
      -1.196953535079956,
      0.9190941452980042,
      -0.41678857803344727,
      -0.16721713542938232,
      0.421093612909317,
      0.25211256742477417,
      0.9129801392555237,
      0.37587469816207886,
      0.9329426288604736,
      -0.21128642559051514,
      0.7618308663368225,
      1.080390214920044,
      0.30479347705841064,
      -0.5578799247741699,
      -0.9619819521903992,
      0.6927072405815125,
      0.4186483323574066,
      -0.37821266055107117,
      -0.7386056184768677,
      0.24062101542949677,
      0.49503079056739807,
      0.0932643860578537,
      1.3355629444122314,
      -0.36988186836242676,
      -1.6932562589645386,
      -0.3655088543891907,
      -0.6667263507843018,
      1.4500559568405151,
      -1.2761070728302002,
      0.23907144367694855,
      0.1952933669090271,
      -0.1851690411567688,
      -0.3343517780303955,
      0.19029036164283752,
      0.21446388959884644,
      -0.3596401512622833,
      2.286358118057251,
      0.6103187799453735,
      0.6847965717315674,
      -0.09547234326601028,
      -1.1737021207809448,
      -1.310473084449768,
      0.4437958002090454,
      1.0912249088287354,
      0.8869925737380981,
      0.08690878748893738,
      0.9273048639297485,
      2.2653329372406006,
      1.0986770391464233,
      -1.7330173254013062,
      1.8533036708831787,
      0.8616725206375122,
      -1.0103479623794556,
      -0.11115779727697372,
      0.047109656035900116,
      -1.2887390851974487,
      1.2325204610824585,
      -0.4798692464828491,
      0.4353165328502655,
      -1.0495197772979736,
      0.46306613087654114,
      0.03132928907871246,
      -0.21581965684890747,
      -0.15224625170230865,
      -0.5451602935791016,
      0.22199957072734833,
      0.4283716678619385,
      -1.6419562101364136,
      1.1890575885772705,
      -1.8214142322540283,
      2.269162654876709,
      -0.891989529132843,
      -0.7934252619743347,
      -0.002968413755297661,
      -0.04471743479371071,
      0.3726150393486023,
      2.4105892181396484,
      0.2415597289800644,
      0.1045975536108017,
      0.22191959619522095,
      0.4786912500858307,
      -2.0340402126312256,
      -0.35604605078697205,
      2.954057455062866,
      -0.6451514959335327,
      -0.19824551045894623,
      -1.7501182556152344,
      -0.18245403468608856,
      0.48140349984169006,
      0.1047767922282219,
      1.851913332939148,
      -0.8760216236114502,
      -1.0880804061889648,
      -2.0587990283966064
    ])
  },
  {
    name: "Breathy",
    color: "#FF9F00",
    z: tf.tensor1d([
      0.009265807457268238,
      -0.47051772475242615,
      3.2547168731689453,
      1.2229996919631958,
      0.16272297501564026,
      -0.15162430703639984,
      -0.6871920824050903,
      -0.6073448061943054,
      -1.3755296468734741,
      -1.2878414392471313,
      0.3348773121833801,
      -0.11692804098129272,
      -0.9901066422462463,
      -0.5427905321121216,
      0.4799646735191345,
      -1.3698761463165283,
      1.4218212366104126,
      -1.5340102910995483,
      -1.0100057125091553,
      -0.37904489040374756,
      -1.8606789112091064,
      -0.6060242056846619,
      -0.8102810382843018,
      1.0970674753189087,
      -0.10451239347457886,
      1.2849009037017822,
      1.7894256114959717,
      0.24690797924995422,
      -0.461437463760376,
      0.9520757794380188,
      -0.28534483909606934,
      1.8273545503616333,
      1.3245232105255127,
      0.23673756420612335,
      -0.0491793192923069,
      0.16132605075836182,
      -1.8401217460632324,
      -0.8776222467422485,
      1.2455815076828003,
      1.0721640586853027,
      1.1268213987350464,
      -0.19570496678352356,
      -0.42882397770881653,
      -0.9954801201820374,
      0.5494852662086487,
      -0.23954680562019348,
      -2.2072298526763916,
      0.5567229390144348,
      0.684895932674408,
      0.9880139827728271,
      0.7316638827323914,
      1.033604383468628,
      1.9245315790176392,
      -0.024157509207725525,
      0.9421180486679077,
      0.4415019154548645,
      -0.6060547828674316,
      0.45091375708580017,
      0.24574291706085205,
      -0.640203058719635,
      0.9583585858345032,
      0.2553043067455292,
      1.2547805309295654,
      -0.45348790287971497,
      1.3264707326889038,
      -0.2679484486579895,
      -0.30889374017715454,
      0.15755344927310944,
      -0.9223324656486511,
      0.707160472869873,
      -0.002423634985461831,
      -0.09277784824371338,
      -0.8337804675102234,
      2.107058525085449,
      0.1404809057712555,
      -1.298887014389038,
      -0.3202154040336609,
      -0.6245941519737244,
      -0.6416559815406799,
      -0.2634265124797821,
      -0.46838781237602234,
      0.14448978006839752,
      -0.9994904398918152,
      -2.475099802017212,
      -1.4334241151809692,
      0.39673417806625366,
      1.7351741790771484,
      -0.8516815304756165,
      0.6415658593177795,
      2.2125000953674316,
      0.3613252639770508,
      0.8064017295837402,
      -0.8764313459396362,
      -0.9198002219200134,
      -1.4095749855041504,
      0.9875172972679138,
      0.7876433730125427,
      1.0341447591781616,
      0.760106086730957,
      -2.166273355484009,
      0.4004366099834442,
      -1.070239782333374,
      -0.09015020728111267,
      -0.21110188961029053,
      0.4370850622653961,
      0.5608312487602234,
      0.29443034529685974,
      0.8591177463531494,
      0.851314127445221,
      1.5431870222091675,
      1.3979288339614868,
      -1.4096202850341797,
      -0.5004903078079224,
      0.9302893280982971,
      1.0183590650558472,
      -0.052196357399225235,
      -1.3818634748458862,
      -0.07604687660932541,
      -0.7074859142303467,
      0.20042085647583008,
      0.009460974484682083,
      -0.3660702705383301,
      -1.13034987449646,
      2.6220202445983887,
      1.2447363138198853,
      0.9195860028266907,
      -0.18711631000041962,
      -0.29090437293052673,
      0.8791022896766663,
      1.5923306941986084,
      0.7808068990707397,
      0.6632763147354126,
      1.0204180479049683,
      -0.44969066977500916,
      0.4547306299209595,
      -1.2233836650848389,
      -1.9677459001541138,
      0.3475392460823059,
      -1.6064918041229248,
      -2.3939340114593506,
      -0.6467897295951843,
      0.26505810022354126,
      0.4041590988636017,
      -0.1792362630367279,
      -0.2472449690103531,
      -0.8281242251396179,
      -0.16932104527950287,
      -1.0653613805770874,
      0.41484251618385315,
      0.7489768266677856,
      0.35719045996665955,
      0.8202661871910095,
      1.4759873151779175,
      0.7076899409294128,
      -1.326582431793213,
      1.4803446531295776,
      0.19856181740760803,
      -0.4119800329208374,
      -1.270227074623108,
      0.045637693256139755,
      -1.0363178253173828,
      0.5261712670326233,
      1.0703083276748657,
      0.5139354467391968,
      0.7037153244018555,
      -0.10654013603925705,
      -1.981105923652649,
      -0.7243672013282776,
      1.7169604301452637,
      1.859487533569336,
      -0.34754708409309387,
      -0.04890286549925804,
      -0.8821017742156982,
      0.6160876154899597,
      -0.4229510724544525,
      1.2188042402267456,
      -0.06378725916147232,
      0.12365055084228516,
      -0.023281047120690346,
      0.04859500378370285,
      0.8718394041061401,
      0.6102690100669861,
      0.6343330144882202,
      -1.4239579439163208,
      1.9220690727233887,
      -0.700317919254303,
      -1.0944854021072388,
      -0.48213860392570496,
      -0.17180007696151733,
      -0.9540077447891235,
      -0.6755421757698059,
      -0.14159342646598816,
      -1.5271146297454834,
      0.5073650479316711,
      -0.22661444544792175,
      0.9658381938934326,
      0.093112513422966,
      -0.8603567481040955,
      -0.3067309558391571,
      0.7785167098045349,
      -0.3228374123573303,
      -1.996376633644104,
      0.6557580232620239,
      0.7893562912940979,
      -0.9281736016273499,
      1.0504323244094849,
      -0.3669414818286896,
      -0.5053833723068237,
      0.5338670611381531,
      0.5974246859550476,
      -1.778450608253479,
      -0.1823330968618393,
      -0.762495219707489,
      0.23088641464710236,
      1.1952346563339233,
      -0.5870864391326904,
      1.6531015634536743,
      0.21468625962734222,
      0.8290123343467712,
      -0.05657988041639328,
      0.31787678599357605,
      -1.9416933059692383,
      -0.7291958332061768,
      -2.4608094692230225,
      0.5537797808647156,
      -0.21438714861869812,
      0.9553048014640808,
      -0.40284010767936707,
      1.7486388683319092,
      -0.9560323357582092,
      1.0359818935394287,
      0.8319538831710815,
      -0.45116493105888367,
      0.41502153873443604,
      -1.0868405103683472,
      -1.1626914739608765,
      0.5727106928825378,
      -0.8189325928688049,
      -0.3714222311973572,
      -1.3632954359054565,
      -0.4563605487346649,
      0.5754520297050476,
      1.3452777862548828,
      0.6470274925231934,
      1.3079224824905396,
      0.3389168679714203,
      0.0696122795343399,
      0.1859433948993683,
      0.0703245997428894,
      -1.9895625114440918,
      -1.7174948453903198,
      -0.6844876408576965,
      0.2509920597076416,
      -1.0132615566253662,
      -0.006563681643456221,
      -0.38693928718566895
    ])
  }
];

export function getZ(name) {
  if (name === "Random") {
    let randZ = tf.randomNormal([N_LATENTS], 0, 1, "float32");
    //randZ.data().then(d => console.log(Array.from(d)));
    return {
      name: "Random",
      z: randZ,
      color: chroma.random()
    };
  } else {
    return _.find(PRESET_ZS, { name });
  }
}
