import React, { useEffect, useState } from "react";
import WebMidi from "webmidi";
import * as _ from "lodash";

import "./MidiInputMenu.scss";

export const MidiInputMenu = ({ currentInput, onChange }) => {
  let [state, setState] = useState("notEnabled");
  let [inputs, setInputs] = useState([]);

  useEffect(() => {
    WebMidi.enable(err => {
      if (err) {
        setState("error");
      } else {
        setState("enabled");

        function updateInputs() {
          setInputs(Array.from(WebMidi.inputs));
        }

        updateInputs();
        WebMidi.addListener("connected", updateInputs);
        WebMidi.addListener("disconnected", updateInputs);
      }
    });
    return () => {
      WebMidi.removeListener();
    };
  }, []);

  useEffect(() => {
    if (!currentInput || inputs.indexOf(currentInput) < 0) {
      onChange(_.first(inputs));
    }
  }, [inputs, currentInput, onChange]);

  function onInputChange(id) {
    console.log("chg to", id, _.find(inputs, { id }));
    onChange(_.find(inputs, { id }));
  }

  function renderState() {
    if (state === "notEnabled") {
      return <span>Enabling MIDI&hellip;</span>;
    } else if (state === "error") {
      return <span>Could not enable MIDI</span>;
    } else if (inputs.length === 0) {
      return <span>No inputs connected</span>;
    } else {
      return (
        <select
          value={currentInput ? currentInput.id : ""}
          onChange={evt => onInputChange(evt.target.value)}
        >
          {inputs.map(input => (
            <option key={input.id} value={input.id}>
              {input.name}
            </option>
          ))}
        </select>
      );
    }
  }
  return (
    <div className="midiInputMenu">
      <div>MIDI Input</div> {renderState()}
    </div>
  );
};
