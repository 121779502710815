import React, { useEffect, useCallback } from "react";
import EventListener from "react-event-listener";
import { PianoKeyboard } from "./PianoKeyboard";
import { KEYBOARD_KEYS } from "./constants";

export const Keyboard = React.memo(
  ({
    disabled,
    currentMidiInput,
    activePlayers,
    onKeyChanges,
    onWtModulation
  }) => {
    let keyDown = useCallback(
      note => {
        if (disabled) return;
        onKeyChanges([["down", note]]);
      },
      [disabled, onKeyChanges]
    );

    let keyUp = useCallback(
      note => {
        if (disabled) return;
        onKeyChanges([["up", note]]);
      },
      [disabled, onKeyChanges]
    );

    useEffect(() => {
      if (currentMidiInput) {
        currentMidiInput.addListener("noteon", "all", ({ note }) =>
          keyDown(note.number)
        );
        currentMidiInput.addListener("noteoff", "all", ({ note }) =>
          keyUp(note.number)
        );
        currentMidiInput.addListener("pitchbend", "all", ({ value }) =>
          onWtModulation(value)
        );

        return () => {
          currentMidiInput.removeListener();
        };
      }
    }, [currentMidiInput, keyDown, keyUp, onWtModulation]);

    return (
      <>
        <PianoKeyboard
          activePlayers={activePlayers}
          onKeyDown={keyDown}
          onKeyUp={keyUp}
        />
        {KEYBOARD_KEYS.map(([keyCode, note]) => (
          <React.Fragment key={note}>
            <EventListener
              target="document"
              onKeyDown={evt =>
                evt.keyCode === keyCode && !evt.repeat && keyDown(note)
              }
            />
            <EventListener
              target="document"
              onKeyUp={evt => evt.keyCode === keyCode && keyUp(note)}
            />
          </React.Fragment>
        ))}
      </>
    );
  }
);
