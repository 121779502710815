export function resample(input, targetRate) {
  let resamplingRatio = targetRate / input.sampleRate;
  let finalLength = input.length * resamplingRatio;
  let off = new OfflineAudioContext(
    input.numberOfChannels,
    finalLength,
    targetRate
  );
  let source = off.createBufferSource();
  source.buffer = input;
  source.connect(off.destination);
  source.start(0);

  return off.startRendering();
}
