import React, { useState } from "react";
import classNames from "classnames";
import * as _ from "lodash";
import EventListener from "react-event-listener";
import keycode from "keycode";

import { KEYBOARD_KEYS } from "./constants";

import "./PianoKeyboard.scss";

const MIN_NOTE = 60;
const MAX_NOTE = 84;
const NOTE_RANGE = MAX_NOTE - MIN_NOTE;
const N_WHITES = NOTE_RANGE - accidentalsBefore(MAX_NOTE);
const NOTE_WIDTH = 100 / N_WHITES;

export const PianoKeyboard = React.memo(
  ({ activePlayers, onKeyDown, onKeyUp }) => {
    let [isMouseDown, setMouseDown] = useState(false);
    let [isMouseOverKeyboard, setMouseOverKeyboard] = useState(false);

    function mouseDown(note, evt) {
      setMouseDown(true);
      onKeyDown(note);
      evt.stopPropagation();
      evt.preventDefault();
    }

    function mouseUp(note) {
      setMouseDown(false);
      onKeyUp(note);
    }

    function mouseEnter(note) {
      if (isMouseDown) {
        onKeyDown(note);
      }
    }

    function mouseLeave(note) {
      if (isMouseDown) {
        onKeyUp(note);
      }
    }

    return (
      <div
        className={classNames("pianoKeyboard", { isMouseOverKeyboard })}
        onMouseEnter={() => setMouseOverKeyboard(true)}
        onMouseLeave={() => setMouseOverKeyboard(false)}
      >
        <EventListener
          target={document}
          onMouseUp={() => setMouseDown(false)}
        />
        {_.range(MIN_NOTE, MAX_NOTE).map((note, idx) => (
          <div
            key={note}
            className={classNames("pianoKeyboard--keyBlock", {
              isBlack: isAccidental(note),
              isActive: !!activePlayers[note]
            })}
            style={{
              left: `${getKeyLeft(note, idx)}%`,
              width: `${getKeyWidth(note)}%`
            }}
            onMouseDown={evt => mouseDown(note, evt)}
            onMouseUp={evt => mouseUp(note, evt)}
            onMouseEnter={evt => mouseEnter(note, evt)}
            onMouseLeave={evt => mouseLeave(note, evt)}
          >
            <div className="pianoKeyboard--key">
              {getKeyboardKey(note) && (
                <span className="pianoKeyboard--keyGuide">
                  {getKeyboardKey(note)}
                </span>
              )}
            </div>
          </div>
        ))}
      </div>
    );
  }
);

function getKeyLeft(note, idx) {
  return (
    (idx - accidentalsBefore(note) - (isAccidental(note) ? 0.3 : 0)) *
    NOTE_WIDTH
  );
}

function getKeyWidth(note) {
  return isAccidental(note) ? 60 / N_WHITES : 100 / N_WHITES;
}

function isAccidental(midiNote) {
  let pc = midiNote % 12;
  return pc === 1 || pc === 3 || pc === 6 || pc === 8 || pc === 10;
}

function accidentalsBefore(midiNote) {
  let n = 0;
  for (let i = midiNote - 1; i >= MIN_NOTE; i--) {
    if (isAccidental(i)) {
      n++;
    }
  }
  return n;
}

let getKeyboardKey = _.memoize(note => {
  let match = _.find(KEYBOARD_KEYS, k => k[1] === note);
  if (match) {
    return keycode.names[match[0]];
  }
  return null;
});
