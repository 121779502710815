import React, {
  useEffect,
  useState,
  useMemo,
  useRef,
  useCallback
} from "react";
import { Canvas } from "react-three-fiber";
import * as _ from "lodash";
import Tone from "tone";
import anime from "animejs";
import chroma from "chroma-js";
import classNames from "classnames";

import { generateWaveforms } from "./waveformGenerator";

import {
  POLYPHONY,
  NUM_SPECTRAL_FREQUENCIES,
  PLAYBACK_SR,
  FREQUENCIES_DEPTH,
  FREQUENCIES_WIDTH,
  VIEW_SWITCH_EASING,
  VIEW_SWITCH_DURATION
} from "./constants";
import { getZ } from "./zs";
import { getAudioCtx } from "./audio";
import { WavetablePlayerNode } from "./WavetablePlayerNode";
import { CameraControls } from "./CameraControls";

import { Frequencies } from "./Frequencies";
import { SoundControls } from "./SoundControls";
import { EndpointControls } from "./EndpointControls";

import "./App.scss";
import { LoadingIndicator } from "./LoadingIndicator";
import { IntroDialog } from "./IntroDialog";

const HEAD_ON_PLANE_ROTATION = { x: 0, y: 0, z: 0 };
const REGULAR_PLANE_ROTATION = { x: degToRad(45), y: degToRad(70), z: 0 };
const MAX_GAIN = 0.4;

function App() {
  let [started, setStarted] = useState(false);
  let [introDialogShowing, setIntroDialogShowing] = useState(true);
  let [processing, setProcessing] = useState(false);
  let [audioCtx, setAudioCtx] = useState(null);
  let [headOnView, setHeadOnView] = useState(false);
  let [waveformData, setWaveformdata] = useState(null);
  let [leftZ, setLeftZ] = useState(() => getZ("Breathy"));
  let [rightZ, setRightZ] = useState(() => getZ("Plucky"));
  let colorScale = useMemo(
    () => chroma.scale([leftZ.color, rightZ.color]).mode("lab"),
    [leftZ, rightZ]
  );
  let [wtModulationType, setWtModulationType] = useState("sine");
  let [wtModulationFreq, setWtModulationFreq] = useState(0.1);
  let [wtModulationSpread, setWtModulationSpread] = useState(6);
  let [wtManualTargetPos, setWtManualTargetPos] = useState(0);
  let groupRef = useRef();
  let audioCtxState = audioCtx && audioCtx.state;
  let players;

  useEffect(() => {
    getAudioCtx().then(setAudioCtx);
  }, []);

  let wtOscs = useMemo(() => {
    if (!audioCtx) return;
    return _.range(POLYPHONY).map(idx => {
      let wtOsc = new Tone.Oscillator(0.1);
      wtOsc.type = "sine";
      wtOsc.phase = idx * 5;
      wtOsc.start();
      return wtOsc;
    });
  }, [audioCtx]);

  useEffect(() => {
    if (wtOscs) {
      if (wtModulationType === "manual") {
        wtOscs.forEach(osc => {
          osc.stop();
          osc.disconnect();
        });
      } else {
        wtOscs.forEach((wtOsc, idx) => {
          if (wtOsc.state !== "started") {
            wtOsc.start();
            players[idx].player.wavetablePos.value = 0;
            wtOsc.connect(players[idx].player.wavetablePos);
          }
          wtOsc.frequency.value = wtModulationFreq;
          wtOsc.type = wtModulationType;
          wtOsc.phase = idx * wtModulationSpread;
        });
      }
    }
  }, [wtOscs, wtModulationFreq, wtModulationType, wtModulationSpread, players]);

  useEffect(() => {
    if (wtModulationType === "manual") {
      let shift = (players.length - 1) / 2;
      for (let i = 0; i < players.length; i++) {
        let player = players[i];
        let target =
          wtManualTargetPos + (wtModulationSpread * (i - shift)) / 180;
        if (target > 1) {
          target = 1 - (target - 1);
        }
        if (target < -1) {
          target = -1 - (target + 1);
        }
        let distance = target - player.player.wavetablePos.value;

        player.player.wavetablePos.setTargetAtTime(
          target,
          audioCtx.currentTime,
          Math.max(0.1, Math.abs(distance) / 2)
        );
      }
    }
  }, [
    audioCtx,
    players,
    wtModulationType,
    wtManualTargetPos,
    wtModulationSpread
  ]);

  let eqAnalyser = useMemo(() => {
    if (!audioCtx) return;

    let analyser = audioCtx.createAnalyser();
    analyser.fftSize = NUM_SPECTRAL_FREQUENCIES * 2;
    analyser.smoothingTimeConstant = 0.85;
    return analyser;
  }, [audioCtx]);

  players = useMemo(() => {
    if (!audioCtx || audioCtxState !== "running" || !eqAnalyser) return;

    let reverb = new Tone.Reverb({ decay: 2, wet: 0.3 });
    reverb.generate();
    reverb.connect(audioCtx.destination);

    return _.range(POLYPHONY).map(idx => {
      let player = new WavetablePlayerNode(audioCtx);
      wtOscs[idx].connect(player.wavetablePos);

      let lpf = new Tone.Filter(PLAYBACK_SR / 2, "lowpass");

      let gain = new Tone.Gain(MAX_GAIN);

      let analyser = audioCtx.createAnalyser();
      analyser.fftSize = NUM_SPECTRAL_FREQUENCIES * 2;
      analyser.smoothingTimeConstant = 0.85;

      player.connect(lpf);
      lpf.connect(gain);
      gain.connect(analyser);
      gain.connect(reverb);
      gain.connect(eqAnalyser);
      return { player, analyser, gain };
    });
  }, [audioCtx, wtOscs, audioCtxState, eqAnalyser]);

  useEffect(() => {
    (() => {
      if (!started || audioCtxState !== "running") return;
      setProcessing(true);
      setTimeout(async () => {
        let waveformData = await generateWaveforms(leftZ, rightZ, audioCtx);
        setWaveformdata(waveformData);
        setProcessing(false);
      }, 20);
    })();
  }, [started, leftZ, rightZ, audioCtx, audioCtxState]);

  useEffect(() => {
    if (players && waveformData) {
      for (let player of players) {
        player.player.wavetable = waveformData;
      }
    }
  }, [players, waveformData]);

  useEffect(() => {
    if (!groupRef.current) return;

    let rotation = {
      x: groupRef.current.rotation.x,
      y: groupRef.current.rotation.y,
      z: groupRef.current.rotation.z
    };
    let finalRotation = headOnView
      ? HEAD_ON_PLANE_ROTATION
      : REGULAR_PLANE_ROTATION;
    anime({
      targets: rotation,
      ...finalRotation,
      easing: VIEW_SWITCH_EASING,
      duration: VIEW_SWITCH_DURATION,
      update: () => {
        groupRef.current.rotation.set(rotation.x, rotation.y, rotation.z);
      }
    });
  }, [headOnView, groupRef.current]);

  let [activePlayers, setActivePlayers] = useState({});
  let [currentMidiInput, setCurrentMidiInput] = useState(null);

  let start = useCallback(() => {
    audioCtx && audioCtx.resume();
    setStarted(true);
    setIntroDialogShowing(false);
  }, [audioCtx]);

  function playNote(voice, note, wtPos) {
    players[voice].gain.gain.setValueAtTime(0, audioCtx.currentTime);
    players[voice].gain.gain.linearRampToValueAtTime(
      MAX_GAIN,
      audioCtx.currentTime + 0.07
    );
    players[voice].player.note = note;
    players[voice].player.start(audioCtx.currentTime);
  }

  function stopNote(note, activePlayers) {
    let { voice } = activePlayers[note];
    players[voice].gain.gain.setValueAtTime(MAX_GAIN, audioCtx.currentTime);
    players[voice].gain.gain.linearRampToValueAtTime(
      0,
      audioCtx.currentTime + 0.07
    );
    players[voice].player.stop(audioCtx.currentTime);
  }

  function onKeyChanges(keyChanges) {
    if (processing || !players) return;
    setActivePlayers(activePlayers => {
      for (let [cmd, note] of keyChanges) {
        switch (cmd) {
          case "down":
            if (activePlayers[note]) {
              continue;
            }
            let activeVoices = _.values(activePlayers)
              .filter(_.identity)
              .map(p => p.voice);
            for (let voice = 0; voice < POLYPHONY; voice++) {
              if (activeVoices.indexOf(voice) < 0) {
                playNote(voice, note);
                activePlayers = {
                  ...activePlayers,
                  [note]: { id: _.uniqueId(), voice }
                };
                break;
              }
            }
            break;
          case "up":
            if (activePlayers[note]) {
              stopNote(note, activePlayers);
              activePlayers = { ...activePlayers, [note]: null };
            }
            break;
          default:
            console.error("Unknown key change command", cmd);
            break;
        }
      }
      return activePlayers;
    });
  }

  let onMouseMove = useCallback(pos => {
    setWtManualTargetPos(Math.max(0, Math.min(1, 1 - pos)) * 2 - 1);
  }, []);

  let onLeftZChange = useCallback(n => {
    setLeftZ(getZ(n));
  }, []);
  let onNewRandomZLeft = useCallback(() => {
    setLeftZ(getZ("Random"));
  }, []);
  let onRightZChange = useCallback(n => {
    setRightZ(getZ(n));
  }, []);
  let onNewRandomZRight = useCallback(() => {
    setRightZ(getZ("Random"));
  }, []);

  let showIntroDialog = useCallback(() => {
    setIntroDialogShowing(true);
  }, []);

  return (
    <div className="app">
      {started && (
        <Canvas
          onCreated={({ scene, camera }) => {
            //scene.background = new THREE.Color().setHSL(0.6, 0, 1);
            //scene.fog = new THREE.Fog(scene.background, 1, 5000);
            camera.far = 5000;
            return Promise.resolve();
          }}
          onClick={() => setHeadOnView(!headOnView)}
        >
          <CameraControls headOnView={headOnView} />
          <ambientLight />
          <pointLight position={[0, 0, 0]} />
          <mesh onPointerMove={e => headOnView && onMouseMove(1 - e.uv.x)}>
            <planeBufferGeometry
              attach="geometry"
              args={[FREQUENCIES_DEPTH, FREQUENCIES_WIDTH]}
            />
            <meshBasicMaterial
              attach="material"
              color="white"
              transparent
              opacity={0}
              alphaTest={0.5}
            />
          </mesh>
          <group ref={groupRef} position={[0, 0, 0]}>
            {players &&
              players.map((player, idx) => (
                <Frequencies
                  key={idx}
                  player={player}
                  colorScale={colorScale}
                  headOnView={headOnView}
                />
              ))}
            <mesh
              castShadow
              receiveShadow
              position={[0, 0, 0]}
              rotation={[(Math.PI * 3) / 2, 0, 0]}
              onPointerMove={e => !headOnView && onMouseMove(e.uv.y)}
            >
              <planeBufferGeometry
                attach="geometry"
                args={[FREQUENCIES_DEPTH, FREQUENCIES_WIDTH]}
              />
              <meshLambertMaterial
                attach="material"
                color="white"
                transparent
                opacity={0.75}
              />
            </mesh>
          </group>
        </Canvas>
      )}
      {started && (
        <EndpointControls
          endpoint="left"
          disabled={processing}
          z={leftZ}
          onZChange={onLeftZChange}
          onNewRandomZ={onNewRandomZLeft}
        />
      )}
      {started && (
        <div className="switchView">
          <button
            className={classNames("switchViewButton", {
              is2D: headOnView,
              is3D: !headOnView
            })}
            onClick={() => setHeadOnView(!headOnView)}
          >
            <span className="switchViewButtonLabel switchViewButtonLabel--2D">
              2D
            </span>
            <span className="switchViewButtonLabel switchViewButtonLabel--3D">
              3D
            </span>
          </button>
        </div>
      )}
      {started && (
        <EndpointControls
          endpoint="right"
          disabled={processing}
          z={rightZ}
          onZChange={onRightZChange}
          onNewRandomZ={onNewRandomZRight}
        />
      )}
      {started && (
        <SoundControls
          disabled={processing}
          players={players}
          activePlayers={activePlayers}
          wtModulationFreq={wtModulationFreq}
          wtModulationType={wtModulationType}
          wtModulationSpread={wtModulationSpread}
          currentMidiInput={currentMidiInput}
          eqAnalyser={eqAnalyser}
          colorScale={colorScale}
          onKeyChanges={onKeyChanges}
          onWtModulation={setWtManualTargetPos}
          onWtModulationFreqChange={setWtModulationFreq}
          onWtModulationTypeChange={setWtModulationType}
          onWtModulationSpreadChange={setWtModulationSpread}
          onCurrentMidiInputChange={setCurrentMidiInput}
          onShowIntroDialog={showIntroDialog}
        />
      )}
      {processing && <LoadingIndicator />}
      {introDialogShowing && <IntroDialog started={started} onClose={start} />}
    </div>
  );
}

function degToRad(deg) {
  return (deg / 180) * Math.PI;
}

export default App;
