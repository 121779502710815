import React from "react";
import * as _ from "lodash";
import classNames from "classnames";

import { Keyboard } from "./Keyboard";
import { MidiInputMenu } from "./MidiInputMenu";
import { GraphicEq } from "./GraphicEq";

import "./SoundControls.scss";

export const SoundControls = React.memo(
  ({
    disabled,
    wtModulationType,
    wtModulationFreq,
    wtModulationSpread,
    currentMidiInput,
    players,
    activePlayers,
    eqAnalyser,
    colorScale,
    onWtModulation,
    onWtModulationTypeChange,
    onWtModulationFreqChange,
    onWtModulationSpreadChange,
    onCurrentMidiInputChange,
    onKeyChanges,
    onShowIntroDialog
  }) => {
    return (
      <div className="soundControls">
        <div className="soundControls--header">
          <h1 className="soundControls--headerLogo">GANHarp</h1>
          <a className="soundControls--headerCredit" href="https://ctpt.co">
            By Counterpoint
          </a>
          <button
            className="soundControls--headerInfoToggle"
            onClick={onShowIntroDialog}
          >
            Info
          </button>
        </div>
        <div
          className="soundControls--scanner"
          onChange={evt => onWtModulationTypeChange(evt.target.value)}
        >
          <h2>Scanner</h2>
          {["manual", "sine", "triangle", "sawtooth"].map(type => (
            <label
              key={type}
              className={classNames(type, {
                isCurrent: wtModulationType === type
              })}
            >
              <input
                name="wtModulationType"
                type="radio"
                value={type}
                defaultChecked={wtModulationType === type}
                disabled={disabled}
              />
              {_.capitalize(type)}
            </label>
          ))}
          <label className="scanSpeed">
            Speed
            <input
              type="range"
              min={0.05}
              max={0.8}
              step={0.05}
              value={wtModulationFreq}
              disabled={disabled || wtModulationType === "manual"}
              onChange={evt => {
                onWtModulationFreqChange(+evt.target.value);
                evt.stopPropagation();
              }}
            />
          </label>
          <label className="scanSpread">
            Spread
            <input
              type="range"
              min={0}
              max={180}
              step={5}
              value={wtModulationSpread}
              disabled={disabled}
              onChange={evt => {
                onWtModulationSpreadChange(+evt.target.value);
                evt.stopPropagation();
              }}
            />
          </label>
        </div>
        <Keyboard
          disabled={disabled}
          activePlayers={activePlayers}
          currentMidiInput={currentMidiInput}
          onKeyChanges={onKeyChanges}
          onWtModulation={onWtModulation}
        />
        <div className="soundControls--equaliser">
          <h2>Equaliser</h2>
          {eqAnalyser && (
            <GraphicEq
              eqAnalyser={eqAnalyser}
              players={players}
              colorScale={colorScale}
            />
          )}
          <MidiInputMenu
            currentInput={currentMidiInput}
            onChange={onCurrentMidiInputChange}
          />
        </div>
      </div>
    );
  }
);
