import React, { useMemo, useEffect } from "react";

import _CameraControls from "camera-controls";
import { useThree, useRender } from "react-three-fiber";
import * as THREE from "three";
import * as _ from "lodash";
import anime from "animejs";

import { VIEW_SWITCH_EASING, VIEW_SWITCH_DURATION } from "./constants";

_CameraControls.install({ THREE: THREE });

export const CameraControls = ({ headOnView }) => {
  let { gl, camera } = useThree();
  let clock = useMemo(() => new THREE.Clock(), []);

  let cameraControls = useMemo(() => {
    let controls = new _CameraControls(camera, gl.domElement);
    controls.dollyTo(8.5, false);
    controls.minAzimuthAngle = -0.1;
    controls.maxAzimuthAngle = 0.1;
    controls.dollySpeed = 0;
    controls.truckSpeed = 0;
    controls.polarRotateSpeed = 0;
    controls.azimuthRotateSpeed = 0;
    controls.moveTo(-0.75, -2.75, 0, false);
    return controls;
  }, [gl, camera]);

  useEffect(() => {
    let target = _.pick(cameraControls.getTarget(), "x", "y", "z");
    let final = headOnView
      ? { x: 0, y: 0, z: 0 }
      : { x: -0.75, y: -2.75, z: 0 };
    anime({
      targets: target,
      ...final,
      easing: VIEW_SWITCH_EASING,
      duration: VIEW_SWITCH_DURATION,
      update: () => {
        cameraControls.moveTo(target.x, target.y, target.z, false);
      }
    });
  }, [cameraControls, headOnView]);

  useRender(() => {
    const delta = clock.getDelta();
    cameraControls.update(delta);
  });

  return <></>;
};
