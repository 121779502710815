import React, { useMemo, useEffect, useRef } from "react";
import * as _ from "lodash";

import "./GraphicEq.scss";

export const GraphicEq = React.memo(({ eqAnalyser, colorScale, players }) => {
  let dataArray = useMemo(() => new Uint8Array(eqAnalyser.frequencyBinCount), [
    eqAnalyser.frequencyBinCount
  ]);
  let binRefs = _.range(eqAnalyser.frequencyBinCount).map(() => useRef());

  function getWtPos() {
    if (!players) return 0;
    let wtPosTotal = 0,
      wtPosCount = 0;
    for (let player of players) {
      wtPosTotal += player.player.wtPosition;
      wtPosCount++;
    }
    return wtPosTotal / wtPosCount;
  }

  useEffect(() => {
    let running = true;
    function frame() {
      if (!running) return;
      eqAnalyser.getByteFrequencyData(dataArray);
      let avgWtPos = getWtPos();
      let color = colorScale(avgWtPos).hex();
      for (let i = 0; i < dataArray.length; i++) {
        let val = Math.max(dataArray[i] / 256, 0.02);
        binRefs[i].current.style.transform = `scale(1.1, ${val})`;
        binRefs[i].current.style.backgroundColor = color;
      }
      requestAnimationFrame(frame);
    }
    requestAnimationFrame(frame);

    return () => {
      running = false;
    };
  });

  return (
    <div className="graphicEq">
      {_.range(eqAnalyser.frequencyBinCount).map(binIdx => (
        <div key={binIdx} ref={binRefs[binIdx]} className="graphicEq--band" />
      ))}
    </div>
  );
});
