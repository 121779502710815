import React, { useMemo, useRef, useEffect } from "react";
import * as THREE from "three";
import * as meshline from "three.meshline";

import { apply, useRender } from "react-three-fiber";
import * as _ from "lodash";
import anime from "animejs";

import {
  NUM_SPECTRAL_FREQUENCIES,
  FREQUENCIES_WIDTH,
  FREQUENCIES_DEPTH,
  VIEW_SWITCH_EASING,
  VIEW_SWITCH_DURATION
} from "./constants";

apply(meshline);

const GRAPH_HEIGHT_REGULAR_VIEW = 1.5;
const GRAPH_HEIGHT_HEAD_ON_VIEW = 2.0;

export const Frequencies = React.memo(({ player, colorScale, headOnView }) => {
  let dataArray = useMemo(
    () => new Uint8Array(player.analyser.frequencyBinCount),
    [player.analyser.frequencyBinCount]
  );
  let meshRef = useRef();
  let bottomMeshRef = useRef();
  let lineRef = useRef();
  let geometryRef = useRef();
  let vertices = useMemo(
    () =>
      _.range(NUM_SPECTRAL_FREQUENCIES).map(
        freqIdx =>
          new THREE.Vector3(
            -0.5 * FREQUENCIES_DEPTH +
              (1 / (NUM_SPECTRAL_FREQUENCIES - 1)) *
                freqIdx *
                FREQUENCIES_DEPTH,
            0,
            0
          )
      ),
    []
  );

  let headOnViewRef = useRef(headOnView);
  useEffect(() => {
    headOnViewRef.current = headOnView;
    if (headOnView) {
      let target = {
        z: (player.player.wtPosition - 0.5) * FREQUENCIES_WIDTH
      };
      anime({
        targets: target,
        z: FREQUENCIES_WIDTH / 4,
        easing: VIEW_SWITCH_EASING,
        duration: VIEW_SWITCH_DURATION,
        update: () => {
          meshRef.current.position.z = target.z;
          bottomMeshRef.current.position.z = target.z;
        }
      });
    }
  }, [headOnView, player]);

  let colorScaleRef = useRef(colorScale);
  useEffect(() => {
    colorScaleRef.current = colorScale;
  }, [colorScale]);
  useRender(() => {
    let on = _.isNumber(player.player.wtPosition);
    if (!headOnViewRef.current) {
      let z = (player.player.wtPosition - 0.5) * FREQUENCIES_WIDTH;
      meshRef.current.position.z = z;
      bottomMeshRef.current.position.z = z;
    }
    meshRef.current.visible = on;
    bottomMeshRef.current.visible = on;
    if (!on) return;

    let [r, g, b] = colorScaleRef.current(player.player.wtPosition).gl();
    meshRef.current.material.color.setRGB(r, g, b);

    player.analyser.getByteFrequencyData(dataArray);
    let range = 256;
    let graphHeight = headOnViewRef.current
      ? GRAPH_HEIGHT_HEAD_ON_VIEW
      : GRAPH_HEIGHT_REGULAR_VIEW;
    let updated = false;
    for (let i = 0; i < dataArray.length; i++) {
      let v = 0.005 + (dataArray[i] / range) * graphHeight;
      let vertex = geometryRef.current.vertices[i];
      if (vertex.z !== v) {
        vertex.setComponent(2, v);
        updated = true;
      }
    }
    if (updated) {
      lineRef.current.setGeometry(geometryRef.current);
      meshRef.current.geometry = lineRef.current.geometry;
    }
  });

  return (
    <>
      <mesh ref={meshRef} rotation={[(Math.PI * 3) / 2, 0, 0]}>
        <meshLine ref={lineRef}>
          <geometry ref={geometryRef} vertices={vertices} />
        </meshLine>
        <meshLineMaterial
          attach="material"
          color={"white"}
          lineWidth={0.03}
          depthTest={true}
        />
      </mesh>
      <mesh ref={bottomMeshRef} rotation={[(Math.PI * 3) / 2, 0, 0]}>
        <meshLine onUpdate={self => (self.parent.geometry = self.geometry)}>
          <geometry
            vertices={[
              new THREE.Vector3(-FREQUENCIES_DEPTH / 2, 0, 0),
              new THREE.Vector3(FREQUENCIES_DEPTH / 2, 0, 0)
            ]}
            onUpdate={self => self.parent.setGeometry(self)}
          />
        </meshLine>
        <meshLineMaterial
          attach="material"
          color={"white"}
          lineWidth={0.03}
          depthTest={true}
          transparent
        />
      </mesh>
    </>
  );
});
