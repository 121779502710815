import React from "react";

import "./IntroDialog.scss";

export const IntroDialog = React.memo(({ started, onClose }) => {
  let chrome = getChromeVersion();
  return (
    <div className="introDialog">
      <div className="introDialog--content">
        <p>
          GANHarp by{" "}
          <a target="_blank" rel="noopener noreferrer" href="https://ctpt.co">
            Counterpoint
          </a>{" "}
          is an experimental musical instrument based on AI-generated sounds.
        </p>
        <p>
          {" "}
          It uses{" "}
          <a
            target="_blank"
            rel="noopener noreferrer"
            href="https://magenta.tensorflow.org/"
          >
            Magenta.js
          </a>{" "}
          and the{" "}
          <a
            target="_blank"
            rel="noopener noreferrer"
            href="https://magenta.tensorflow.org/gansynth"
          >
            Magenta GANSynth
          </a>{" "}
          model trained on acoustic instruments to generate continuously
          morphing waveform interpolations, and lets you play them with your
          computer or MIDI keyboard.
        </p>
        <p>
          Note: this app is riding{" "}
          <a
            target="_blank"
            rel="noopener noreferrer"
            href="https://developer.mozilla.org/en-US/docs/Web/API/Worklet"
          >
            the bleeding edge of web technology
          </a>
          , and currently requires Chrome 74 or newer to function.{" "}
          {!chrome && (
            <span className="introDialog--warning">
              Looks like this may not work on your browser, please try Chrome
              74+.
            </span>
          )}
        </p>
        <button className="introDialog--startButton" onClick={onClose}>
          {started ? "Back" : "Play"}
        </button>
      </div>
    </div>
  );
});

function getChromeVersion() {
  var raw = navigator.userAgent.match(/Chrom(e|ium)\/([0-9]+)\./);
  return raw ? parseInt(raw[2], 10) : false;
}
