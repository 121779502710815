// GANSynth constants
export const GANSYNTH_CHECKPOINT = `https://storage.googleapis.com/magentadata/js/checkpoints/gansynth/acoustic_only`;
export const N_LATENTS = 256;
export const MIN_MIDI_PITCH = 24;
export const MAX_MIDI_PITCH = 84;
export const MIDI_PITCHES = MAX_MIDI_PITCH - MIN_MIDI_PITCH + 1;
export const BATCH_SIZE = 8;
export const T = 4.0;
export const GENERATOR_SR = 16000;
export const PLAYBACK_SR = 16000;

// How many notes can play at a time
export const POLYPHONY = 4;

// How many interpolation steps to generate
export const NUM_INTERPS = 32;

// How many bands to divide the spectrum into
export const NUM_SPECTRAL_FREQUENCIES = 32;

// Visualization settings
export const FREQUENCIES_WIDTH = 7;
export const FREQUENCIES_DEPTH = 7;

export const VIEW_SWITCH_EASING = "easeInOutSine";
export const VIEW_SWITCH_DURATION = 500;

export const KEYBOARD_KEYS = [
  [65, 60],
  [87, 61],
  [83, 62],
  [69, 63],
  [68, 64],
  [70, 65],
  [84, 66],
  [71, 67],
  [89, 68],
  [72, 69],
  [85, 70],
  [74, 71],
  [75, 72],
  [79, 73],
  [76, 74],
  [80, 75],
  [186, 76],
  [222, 77]
];
