import React from "react";
import chroma from "chroma-js";

import { PRESET_ZS } from "./zs";

import "./EndpointControls.scss";

export const EndpointControls = React.memo(
  ({ disabled, endpoint, z, onZChange, onNewRandomZ }) => {
    return (
      <div
        className={`endpointControls ${endpoint}`}
        style={{
          "--light-color": z.color,
          "--darker-light-color": chroma(z.color)
            .darken(0.5)
            .hex()
        }}
      >
        <select
          value={z.name}
          onChange={evt => onZChange(evt.target.value)}
          disabled={disabled}
        >
          {PRESET_ZS.map(zOption => (
            <option key={zOption.name} value={zOption.name}>
              {zOption.name}
            </option>
          ))}
          <option value={"Random"}>Random</option>
        </select>
        {z.name === "Random" && (
          <button onClick={onNewRandomZ} disabled={disabled}>
            New random
          </button>
        )}
      </div>
    );
  }
);
